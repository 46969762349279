import { useGenericMutation, RequestType } from "@/hooks/api/useGenericMutation";
import { SearchVendorByReceiptResponse } from "@/types/responses/search-vendo-by-receipt-response";
import { BaseUrls } from "@/utils/baseUrls";
import { useRouter } from "next/navigation";
import { toast } from "sonner";

interface UseVendorSearchProps {
  onModalClose: () => void;
}

export const useVendorSearch = ({ onModalClose }: UseVendorSearchProps) => {
  const router = useRouter();

  const searchVendorByReceipt = useGenericMutation<SearchVendorByReceiptResponse, FormData>({
    baseUrl: BaseUrls.PLAYE,
    isFormData: true,
    endpoint: "/vendor/search-by-receipt",
    onSuccess: (result) => {
      if (!result.vendor.id) {
        onModalClose();
        toast.error("Store Not Found");
        return;
      }

      if (result.isRejected) {
        onModalClose();
        router.push(`/check-status/${result.documentId}`);
      } else {
        router.push("/store/" + result.vendor.id);
      }
    },
    onError: (error) => {
      onModalClose();
      toast.error("Store Not Found");
    },
  });

  const searchVendorByCodeMutation = useGenericMutation<{ id: string }, string>({
    baseUrl: BaseUrls.PLAYE,
    endpoint: "/vendor/get-vendor-by-code",
    onSuccess: (vendor) => {
      if (vendor.id) {
        router.push("/store/" + vendor.id);
      } else {
        toast.error("Store Not Found");
      }
    },
    onError: () => {
      toast.error("Store Not Found");
    },
  });

  const searchVendorByCode = async (code: string) => {
    searchVendorByCodeMutation.mutate(code);
  };

  return {
    searchVendorByReceipt,
    searchVendorByCode,
  };
}; 