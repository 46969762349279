"use client"

import type React from "react"

import { Check, GalleryVerticalEnd, X, CameraIcon, Repeat, FlipHorizontal } from "lucide-react"
import { Button } from "@/components/ui/button"
import { CameraView } from "./camera-view"
import { type FC, useRef, useState, useEffect } from "react"
import { useCamera } from "@/components/ui/camera/camera-provider"
import { cn } from "@/lib/utils"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { ScrollArea } from "@/components/ui/scroll-area"
import type { CameraType } from "./camera-types"
import piexif from "piexifjs"
import { Badge } from "@/components/ui/badge"

interface CameraProps {
  onClosed: () => void
  onCapturedImages: (images: string[]) => void
  fingerprint?: string
  latitude?: number | null
  longitude?: number | null
}

const Camera: FC<CameraProps> = ({ onClosed, onCapturedImages, fingerprint, latitude, longitude }) => {
  const camera = useRef<CameraType | null>(null)
  const { images, addImage, numberOfCameras, resetImages, stopStream } = useCamera()
  const [previewImage, setPreviewImage] = useState<string | null>(null)
  const [orientation, setOrientation] = useState<"portrait" | "landscape">(
    window.innerHeight > window.innerWidth ? "portrait" : "landscape",
  )

  // Handle orientation changes
  useEffect(() => {
    const handleResize = () => {
      setOrientation(window.innerHeight > window.innerWidth ? "portrait" : "landscape")
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const attachExifData = async (imageBlob: Blob): Promise<Blob> => {
    // Create a new image element to get dimensions
    const img = new Image()
    await new Promise((resolve) => {
      img.onload = resolve
      img.src = URL.createObjectURL(imageBlob)
    })

    // Convert blob to base64 for piexif
    const reader = new FileReader()
    const base64Image = await new Promise<string>((resolve) => {
      reader.onloadend = () => resolve(reader.result as string)
      reader.readAsDataURL(imageBlob)
    })

    // Get current date and time in EXIF format (YYYY:MM:DD HH:MM:SS)
    const now = new Date()
    const dateTimeOriginal = now
      .toISOString()
      .replace(/[-T]/g, ":")
      .replace(/\.\d+Z$/, "")

    // Get camera capabilities if available
    let cameraSettings = {
      exposureTime: "1/100",
      fNumber: "2.8",
      isoSpeed: "100",
      focalLength: "4.2",
    }

    if (camera.current?.getCapabilities) {
      const capabilities = camera.current.getCapabilities()
      if (capabilities) {
        cameraSettings = {
          exposureTime: capabilities.exposureTime?.toString() || "1/100",
          fNumber: capabilities.fNumber?.toString() || "2.8",
          isoSpeed: capabilities.iso?.toString() || "100",
          focalLength: capabilities.focalLength?.toString() || "4.2",
        }
      }
    }

    // Get browser and OS info for device metadata
    const userAgent = window.navigator.userAgent
    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent)
    const deviceMake = isMobile ? (userAgent.includes("iPhone") ? "Apple" : "Android Device") : "Web Browser"
    const deviceModel = isMobile ? (userAgent.includes("iPhone") ? "iPhone" : "Android Phone") : "Camera"

    // Convert GPS coordinates to EXIF format (degrees, minutes, seconds)
    const convertToExifGPS = (coordinate: number | null | undefined) => {
      if (!coordinate)
        return [
          [0, 1],
          [0, 1],
          [0, 1],
        ]
      const absolute = Math.abs(coordinate)
      const degrees = Math.floor(absolute)
      const minutes = Math.floor((absolute - degrees) * 60)
      const seconds = Math.floor((absolute - degrees - minutes / 60) * 3600 * 100) / 100
      return [
        [degrees, 1],
        [minutes, 1],
        [seconds * 100, 100],
      ]
    }

    // Prepare EXIF data in piexif format
    const exifObj = {
      "0th": {
        [piexif.ImageIFD.Make]: deviceMake,
        [piexif.ImageIFD.Model]: deviceModel,
        [piexif.ImageIFD.Software]: "Camera App v1.0",
        [piexif.ImageIFD.DateTime]: dateTimeOriginal,
        [piexif.ImageIFD.XResolution]: [72, 1],
        [piexif.ImageIFD.YResolution]: [72, 1],
        [piexif.ImageIFD.Copyright]: "All Rights Reserved",
      },
      Exif: {
        [piexif.ExifIFD.DateTimeOriginal]: dateTimeOriginal,
        [piexif.ExifIFD.DateTimeDigitized]: dateTimeOriginal,
        [piexif.ExifIFD.PixelXDimension]: img.naturalWidth,
        [piexif.ExifIFD.PixelYDimension]: img.naturalHeight,
        [piexif.ExifIFD.ExposureTime]: [1, 100], // 1/100 sec
        [piexif.ExifIFD.FNumber]: [28, 10], // f/2.8
        [piexif.ExifIFD.ISOSpeedRatings]: 100,
        [piexif.ExifIFD.FocalLength]: [42, 10], // 4.2mm
        [piexif.ExifIFD.ColorSpace]: 1, // sRGB
        [piexif.ExifIFD.ExifVersion]: "0231",
        [piexif.ExifIFD.ComponentsConfiguration]: "\x01\x02\x03\x00", // RGB
        [piexif.ExifIFD.FlashpixVersion]: "0100",
        [piexif.ExifIFD.MakerNote]: `Fingerprint:${fingerprint || "unknown"}`,
      },
      GPS: {
        [piexif.GPSIFD.GPSVersionID]: [2, 2, 0, 0],
        [piexif.GPSIFD.GPSLatitudeRef]: latitude && latitude >= 0 ? "N" : "S",
        [piexif.GPSIFD.GPSLatitude]: convertToExifGPS(latitude),
        [piexif.GPSIFD.GPSLongitudeRef]: longitude && longitude >= 0 ? "E" : "W",
        [piexif.GPSIFD.GPSLongitude]: convertToExifGPS(longitude),
        [piexif.GPSIFD.GPSDateStamp]: dateTimeOriginal.split(" ")[0] || "",
        [piexif.GPSIFD.GPSTimeStamp]: (() => {
          try {
            const timePart = dateTimeOriginal.split(" ")[1]
            if (!timePart)
              return [
                [0, 1],
                [0, 1],
                [0, 1],
              ]
            return timePart.split(":").map((n) => [Number.parseInt(n) || 0, 1])
          } catch (error) {
            return [
              [0, 1],
              [0, 1],
              [0, 1],
            ]
          }
        })(),
        [piexif.GPSIFD.GPSProcessingMethod]: "NETWORK",
      },
    }

    // Create EXIF binary
    const exifbytes = piexif.dump(exifObj)

    // Insert EXIF into JPEG
    const newImageData = piexif.insert(exifbytes, base64Image)

    // Convert back to blob
    const byteString = atob(newImageData.split(",")[1])
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ab], { type: "image/jpeg" })
  }

  const handleCapture = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (camera.current) {
      const imageData = camera.current.takePhoto()
      if (imageData) {
        // Convert base64 to blob
        const response = await fetch(imageData)
        const blob = await response.blob()

        // Attach EXIF data
        const processedBlob = await attachExifData(blob)

        // Convert back to base64 for preview
        const reader = new FileReader()
        reader.onloadend = () => {
          const base64data = reader.result as string
          setPreviewImage(base64data)
        }
        reader.readAsDataURL(processedBlob)
      }
    }
  }

  const handleUsePhoto = () => {
    if (previewImage) {
      addImage(previewImage)
      setPreviewImage(null)
      handleOnCapturedImages([previewImage])
    }
  }

  const handleRetake = () => {
    setPreviewImage(null)
  }

  const handleOnClosed = () => {
    stopStream()
    onClosed()
  }

  const handleOnCapturedImages = (images: string[]) => {
    onCapturedImages(images)
    resetImages()
    handleOnClosed()
  }

  return (
    <div className="z-10 flex min-w-[calc(100vw_-_theme(spacing.4))] flex-1 flex-col">
      <div className="relative w-full h-svh bg-black">
        {/* Header Bar */}
        <div className="absolute top-0 left-0 right-0 z-20 flex justify-between items-center p-4 bg-gradient-to-b from-black/70 to-transparent">
          <Button
            className="rounded-full bg-black/40 backdrop-blur-sm hover:bg-black/60 transition-all"
            size="icon"
            variant="ghost"
            onClick={handleOnClosed}
          >
            <X className="w-5 h-5 text-white" />
          </Button>

          <div className="flex items-center gap-2">
            {images.length > 0 && (
              <Badge variant="secondary" className="bg-black/40 backdrop-blur-sm text-white">
                {images.length} {images.length === 1 ? "Photo" : "Photos"}
              </Badge>
            )}

            {images.length > 0 && <Gallery />}
          </div>
        </div>

        {previewImage ? (
          <div className="relative w-full h-full">
            <img src={previewImage || "/placeholder.svg"} alt="Preview" className="w-full h-full object-cover" />

            {/* Preview Controls */}
            <div className="absolute bottom-0 left-0 right-0 p-4 sm:p-6 bg-gradient-to-t from-black/80 to-transparent flex justify-between items-center">
              <Button
                onClick={handleRetake}
                variant="outline"
                className="rounded-full border-white/30 bg-black/30 backdrop-blur-sm text-white hover:bg-black/50 hover:text-white transition-all"
                size="sm"
                aria-label="Retake photo"
              >
                <Repeat className="w-4 h-4 mr-1 sm:mr-2" />
                <span className="sm:inline hidden">Retake</span>
              </Button>

              <Button
                onClick={handleUsePhoto}
                variant="default"
                className="rounded-full bg-white text-black hover:bg-white/90 transition-all"
                size="sm"
                aria-label="Use photo"
              >
                <Check className="w-4 h-4 mr-1 sm:mr-2" />
                <span className="sm:inline hidden">Use Photo</span>
              </Button>
            </div>
          </div>
        ) : (
          <>
            <CameraView ref={camera} />

            {/* Camera Controls */}
            <div
              className={cn(
                "absolute z-20 bg-gradient-to-t from-black/70 to-transparent flex items-center",
                orientation === "portrait"
                  ? "bottom-0 left-0 right-0 p-4 sm:p-6 justify-center"
                  : "bottom-0 top-0 right-0 w-24 flex-col justify-center p-4",
              )}
            >
              <div
                className={cn(
                  "flex items-center w-full max-w-md",
                  orientation === "portrait" ? "justify-between" : "flex-col justify-center gap-8",
                )}
              >
                {/* Camera switcher */}
                {numberOfCameras > 0 && (
                  <div>
                    <SwitchCamera />
                  </div>
                )}

                {/* Capture button */}
                <Button
                  className={cn(
                    "rounded-full bg-white hover:bg-white/90 transition-all p-0 flex items-center justify-center border-4 border-white/30",
                    orientation === "portrait" ? "h-16 w-16" : "h-14 w-14",
                  )}
                  variant="default"
                  onClick={handleCapture}
                  aria-label="Take photo"
                >
                  <div
                    className={cn(
                      "rounded-full bg-white border-4 border-black/10",
                      orientation === "portrait" ? "h-12 w-12" : "h-10 w-10",
                    )}
                  ></div>
                </Button>

                {/* Placeholder for symmetry in portrait mode */}
                {orientation === "portrait" && <div className="w-10 h-10"></div>}
              </div>
            </div>

            {/* Camera mode indicator */}
            <div
              className={cn(
                "absolute z-10 flex justify-center",
                orientation === "portrait" ? "top-16 left-0 right-0" : "top-4 left-1/2 -translate-x-1/2",
              )}
            >
              <Badge variant="secondary" className="bg-black/40 backdrop-blur-sm text-white">
                <CameraIcon className="w-4 h-4 mr-1" /> Photo
              </Badge>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

function SwitchCamera() {
  const { devices, setActiveDeviceId, activeDeviceId, switchCamera } = useCamera()

  if (devices.length === 2) {
    return (
      <Button
        variant="outline"
        size="icon"
        className="rounded-full bg-black/40 backdrop-blur-sm border-white/30 text-white hover:bg-black/60 hover:text-white transition-all"
        onClick={switchCamera}
        aria-label="Switch camera"
      >
        <FlipHorizontal className="w-5 h-5" />
      </Button>
    )
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className="rounded-full bg-black/40 backdrop-blur-sm border-white/30 text-white hover:bg-black/60 hover:text-white transition-all"
          aria-label="Select camera"
        >
          <FlipHorizontal className="w-5 h-5" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Switch Camera</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <Select
            onValueChange={(value: string) => {
              setActiveDeviceId(value)
            }}
            value={activeDeviceId || undefined}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Choose Camera" />
            </SelectTrigger>
            <SelectContent>
              {devices.map((device) => (
                <SelectItem key={device.deviceId} value={device.deviceId}>
                  {device.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  )
}

function Gallery() {
  const { images, removeImage } = useCamera()

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          className="rounded-full bg-black/40 backdrop-blur-sm hover:bg-black/60 transition-all"
          size="icon"
          variant="ghost"
          aria-label="Open gallery"
        >
          <GalleryVerticalEnd className="w-5 h-5 text-white" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[90vw] md:max-w-[600px] max-h-[90vh]">
        <DialogHeader>
          <DialogTitle className="flex items-center">
            <GalleryVerticalEnd className="w-5 h-5 mr-2" />
            Gallery ({images.length} {images.length === 1 ? "Photo" : "Photos"})
          </DialogTitle>
        </DialogHeader>
        <ScrollArea className="h-[calc(80vh-_theme(spacing.16))]">
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-3 p-1">
            {images.map((image, index) => (
              <div key={index} className="relative group overflow-hidden rounded-lg aspect-square">
                <img
                  src={image || "/placeholder.svg"}
                  alt={`captured photo ${index + 1}`}
                  className="w-full h-full object-cover transition-transform group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity flex items-end justify-end p-2">
                  <Button
                    className="w-8 h-8 rounded-full"
                    size="icon"
                    variant="destructive"
                    onClick={() => removeImage(index)}
                    aria-label={`Delete photo ${index + 1}`}
                  >
                    <X className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}

export default Camera

